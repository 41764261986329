import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { Enquiry } from '../model/enquiry';

export interface enquirystatemodel {
  list: Enquiry[];
  item: Enquiry | null;
}

export class FetchEnquiryList {
  static readonly type = '[FetchEnquiryList] EnquiryState';
}

export class FetchEnquiryById {
  static readonly type = '[FetchEnquiryById] EnquiryState';
  constructor(public id: string) {}
}

@State<enquirystatemodel>({
  name: 'enquiry',
  defaults: {
    list: [
      new Enquiry(
        '1',
        new Date(),
        '1',
        'Arun Raj',
        '1',
        'Test Enquiry sample',
        '1',
        '1'
      ),
      new Enquiry(
        '2',
        new Date(),
        '1',
        'Arun Raj',
        '1',
        'Test Enquiry sample',
        '1',
        '1'
      ),
      new Enquiry(
        '3',
        new Date(),
        '1',
        'Arun Raj',
        '1',
        'Test Enquiry sample',
        '1',
        '1'
      ),
      new Enquiry(
        '4',
        new Date(),
        '1',
        'Arun Raj',
        '1',
        'Test Enquiry sample',
        '1',
        '1'
      ),
    ],
    item: null,
  },
})
@Injectable()
export class EnquiryState {
  constructor() {}

  @Selector()
  static getList(state: enquirystatemodel): any[] {
    return state.list;
  }

  @Selector()
  static getItem(state: enquirystatemodel): any {
    return state.item;
  }

  @Action(FetchEnquiryList)
  fetchEnquiryList(
    states: StateContext<enquirystatemodel>,
    action: FetchEnquiryList
  ) {}

  @Action(FetchEnquiryById)
  fetchClientById(
    states: StateContext<enquirystatemodel>,
    action: FetchEnquiryById
  ) {
    // states.patchState({
    //   item:
    // });

    return EMPTY;
  }
}
