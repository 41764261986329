export class EnquiryForm {
  constructor(
    private doe: Date,
    private empId: string,
    private clientName: string,
    private clientId: string,
    private detail: string,
    private refType: string,
    private document: string
  ) {}
}

export class Enquiry extends EnquiryForm {
  constructor(
    private id: string,
    doe: Date,
    empId: string,
    clientName: string,
    clientId: string,
    detail: string,
    refType: string,
    document: string
  ) {
    super(doe, empId, clientName, clientId, detail, refType, document);
  }
}
